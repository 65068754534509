@import "~@angular/material/prebuilt-themes/indigo-pink.css";

* {
  -webkit-user-select: none;  /* Chrome, Safari, Opera */
  -moz-user-select: none;     /* Firefox */
  -ms-user-select: none;      /* IE/Edge */
  user-select: none;          /* Standard syntax */
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    margin-top: 4.5em;
    text-align: center;
}

.plain {
    background-color: #d3d6da;
    color: #000000;
};
.absent {
    background-color: #787c7e;
    color: #ffffff;
};
.present {
    background-color: #c9b458;
    color: #ffffff;
}
.correct {
    background-color: #6aaa64;
    color: #ffffff;
}
html, body { height: 100%; }
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    touch-action: none;
}
